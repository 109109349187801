import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchSaleChannels,
  createSaleChannel,
} from "../../services/saleChannelService";
import { useForm } from "react-hook-form";

const SaleChannelTable = () => {
  const {
    data: saleChannels,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["saleChannels"],
    queryFn: fetchSaleChannels,
  });

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: addSaleChannel } = useMutation({
    mutationFn: createSaleChannel,
    onSuccess: () => {
      queryClient.invalidateQueries("saleChannels");
      setOpen(false);
      reset();
    },
  });

  const onSubmit = (data) => {
    addSaleChannel(data);
  };

  if (isLoading) return <Typography>Loading sale channels...</Typography>;
  if (error) return <Typography>Error fetching sale channels.</Typography>;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        sx={{ marginBottom: 2 }}
      >
        Agregar Canal de Venta
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {saleChannels.map((channel) => (
              <TableRow key={channel.id}>
                <TableCell>{channel.id}</TableCell>
                <TableCell>{channel.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={() => {
          reset();
          setOpen(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Agregar Canal de Venta</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Canal de Venta"
              {...register("name", {
                required: "El canal de venta es requerido",
              })}
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={
                errors.name?.message || "Ejemplo: Marketplace, Instagram."
              }
            />
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="info">
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Agregar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SaleChannelTable;
