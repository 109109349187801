import React from "react";
import { Container, Typography } from "@mui/material";
import PaymentMethodTable from "../components/PaymentMethodTable/PaymentMethodTable";

function PaymentMethods() {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Métodos de Pago
      </Typography>
      <PaymentMethodTable />
    </Container>
  );
}

export default PaymentMethods;
