import api from "./authService";

export const fetchSales = async () => {
  const response = await api.get("/sales");
  return response.data;
};

export const createSale = async (sale) => {
  const response = await api.post("/sales", sale);
  return response.data;
};
