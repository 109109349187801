import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "../../services/productService";

const ShipmentForm = ({ open, onClose, onSubmit }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
    trigger,
  } = useForm({
    defaultValues: {
      orderedDate: new Date().toISOString().split("T")[0],
      taxCost: 0,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const { data: products } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });

  const handleAddProduct = () => {
    append({ productId: "", units: "", purchaseCost: "" });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const submitHandler = async (data) => {
    if (fields.length === 0) {
      await trigger("products");
      return;
    }
    onSubmit(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Agregar Envío</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitHandler)}>
          <TextField
            {...register("shippingCompany", {
              required: "El nombre de la empresa es requerido",
            })}
            label="Empresa de Envío"
            fullWidth
            margin="normal"
            error={!!errors.shippingCompany}
            helperText={errors.shippingCompany?.message}
          />
          <TextField
            {...register("orderedDate", {
              required: "La fecha de orden es requerida",
            })}
            label="Fecha de Orden"
            type="date"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.orderedDate}
            helperText={errors.orderedDate?.message}
          />
          <TextField
            {...register("shippingCost", {
              required: "El costo es requerido",
              valueAsNumber: true,
            })}
            label="Costo de envío"
            type="number"
            fullWidth
            margin="normal"
            error={!!errors.shippingCost}
            helperText={errors.shippingCost?.message}
          />
          <TextField
            {...register("taxCost", {
              required: "El costo de impuesto es requerido",
              valueAsNumber: true,
            })}
            label="Costo de Impuestos"
            type="number"
            fullWidth
            margin="normal"
            error={!!errors.taxCost}
            helperText={errors.taxCost?.message}
          />

          <Button onClick={handleAddProduct} sx={{ marginTop: 2 }}>
            Agregar Producto
          </Button>

          {fields.length === 0 && isSubmitted && (
            <Typography color="error" sx={{ mt: 2 }}>
              Debes al menos agregar un producto
            </Typography>
          )}

          {fields.map((field, index) => (
            <Grid container spacing={2} key={field.id} sx={{ marginTop: 2 }}>
              <Grid item xs={5}>
                <TextField
                  select
                  label="Product"
                  {...register(`products.${index}.productId`, {
                    required: "Producto es requerido",
                  })}
                  fullWidth
                  error={!!errors.products?.[index]?.productId}
                  helperText={errors.products?.[index]?.productId?.message}
                >
                  {products?.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  {...register(`products.${index}.units`, {
                    required: "Unidades son requeridas",
                    valueAsNumber: true,
                  })}
                  label="Unidades"
                  type="number"
                  fullWidth
                  error={!!errors.products?.[index]?.units}
                  helperText={errors.products?.[index]?.units?.message}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  {...register(`products.${index}.purchaseCost`, {
                    required: "El costo es requerido",
                    valueAsNumber: true,
                  })}
                  label="Costo de Compra"
                  type="number"
                  fullWidth
                  error={!!errors.products?.[index]?.purchaseCost}
                  helperText={errors.products?.[index]?.purchaseCost?.message}
                />
              </Grid>

              <Grid item xs={2} alignContent={"center"}>
                <Button onClick={() => remove(index)} color="error">
                  Remover
                </Button>
              </Grid>
            </Grid>
          ))}

          <DialogActions>
            <Button onClick={onClose} color="info">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Agregar Envío
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ShipmentForm;
