import React from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const ProductForm = ({ open, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    reset();
    onClose();
  };

  const submitHandler = (data) => {
    onSubmit(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Agregar Producto</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitHandler)}>
          <TextField
            {...register("name", { required: "El nombre es requerido" })}
            label="Nombre de Producto"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <TextField
            {...register("suggestedSalePrice", {
              required: "El precio sugerido es requerido",
              valueAsNumber: true,
              min: { value: 1, message: "El precio debe ser mayor a 0" },
            })}
            label="Precio de Venta Sugerido"
            type="number"
            fullWidth
            margin="normal"
            error={!!errors.suggestedSalePrice}
            helperText={errors.suggestedSalePrice?.message}
          />
          <DialogActions>
            <Button onClick={onClose} color="info">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Agregar producto
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProductForm;
