import api from "./authService";

export const fetchSaleChannels = async () => {
  const response = await api.get("/sale-channels");
  return response.data;
};

export const createSaleChannel = async (channel) => {
  const response = await api.post("/sale-channels", channel);
  return response.data;
};
