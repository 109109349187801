
import React from "react";
import { Typography } from "@mui/material";

function Home() {
  return (
    <div>
      <Typography variant="h4">Home Page</Typography>
      {/* Aquí irá la funcionalidad de las ventas */}
    </div>
  );
}

export default Home;
