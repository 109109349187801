import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  MenuItem,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "../../services/productService";
import { fetchPaymentMethods } from "../../services/paymentMethodService";
import { fetchSaleChannels } from "../../services/saleChannelService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const SaleForm = ({ open, onClose, onSubmit, errorMessage, isLoading }) => {
  const saleDate = dayjs().format("YYYY-MM-DD");

  const {
    register,
    control,
    reset,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      saleDate,
      products: [{ productId: "", quantity: "", unitPrice: "" }],
      payments: [{ paymentMethodId: "", payedAmount: "", currency: "" }],
    },
  });

  const {
    fields: productFields,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: "products",
  });

  const {
    fields: paymentFields,
    append: appendPayment,
    remove: removePayment,
  } = useFieldArray({
    control,
    name: "payments",
  });

  const { data: products } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });

  const { data: paymentMethods } = useQuery({
    queryKey: ["paymentMethods"],
    queryFn: fetchPaymentMethods,
  });

  const { data: saleChannels } = useQuery({
    queryKey: ["saleChannels"],
    queryFn: fetchSaleChannels,
  });

  const handleAddProduct = () => {
    appendProduct({ productId: "", quantity: "", unitPrice: "" });
  };

  const handleAddPayment = () => {
    appendPayment({ paymentMethodId: "", payedAmount: "", currency: "" });
  };

  const handleClose = () => {
    reset();
    clearErrors();
    onClose();
  };

  const submitHandler = (data) => {
    onSubmit(data, () => {
      reset();
      clearErrors();
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Agregar Venta</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitHandler)}>
          {errorMessage && (
            <Typography color="error" gutterBottom>
              {errorMessage}
            </Typography>
          )}
          <TextField
            label="Nombre del Comprador"
            {...register("buyerName", { required: "Buyer name is required" })}
            fullWidth
            margin="normal"
            error={!!errors.buyerName}
            helperText={errors.buyerName?.message}
          />

          <TextField
            {...register("conversionRate", {
              required: "Conversion rate is required",
              valueAsNumber: true,
            })}
            label="Ratio de Conversión a BS"
            type="number"
            fullWidth
            margin="normal"
            error={!!errors.conversionRate}
            helperText={errors.conversionRate?.message}
          />

          <TextField
            {...register("deliveryCost", {
              required: "Delivery cost is required",
              valueAsNumber: true,
            })}
            label="Precio Delivery"
            type="number"
            fullWidth
            margin="normal"
            error={!!errors.deliveryCost}
            helperText={errors.deliveryCost?.message}
          />

          <TextField
            {...register("saleDate")}
            label="Fecha de Venta"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            select
            label="Canal de Venta"
            {...register("saleChannelId", {
              required: "Sale Channel is required",
            })}
            fullWidth
            margin="normal"
            error={!!errors.saleChannelId}
            helperText={errors.saleChannelId?.message}
          >
            {saleChannels?.map((channel) => (
              <MenuItem key={channel.id} value={channel.id}>
                {channel.name}
              </MenuItem>
            ))}
          </TextField>

          <Button onClick={handleAddProduct} sx={{ marginTop: 2 }}>
            Agregar Producto
          </Button>

          {productFields.map((field, index) => (
            <Grid container spacing={2} key={field.id} sx={{ marginTop: 2 }}>
              <Grid item xs={4}>
                <Autocomplete
                  options={products || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  onChange={(_, value) => {
                    setValue(`products.${index}.productId`, value?.id || "");
                    clearErrors(`products.${index}.productId`);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Producto"
                      error={!!errors.products?.[index]?.productId}
                      helperText={errors.products?.[index]?.productId?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  {...register(`products.${index}.quantity`, {
                    required: "La cantidad es requerida",
                    valueAsNumber: true,
                  })}
                  label="Cantidad"
                  type="number"
                  fullWidth
                  error={!!errors.products?.[index]?.quantity}
                  helperText={errors.products?.[index]?.quantity?.message}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  {...register(`products.${index}.unitPrice`, {
                    required: "Este campo es requerido",
                    valueAsNumber: true,
                  })}
                  label="Precio de Venta Unitario"
                  type="number"
                  fullWidth
                  error={!!errors.products?.[index]?.unitPrice}
                  helperText={errors.products?.[index]?.unitPrice?.message}
                />
              </Grid>

              <Grid item xs={2}>
                {productFields.length > 1 && (
                  <Button onClick={() => removeProduct(index)} color="error">
                    Remover
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}

          <Button onClick={handleAddPayment} sx={{ marginTop: 2 }}>
            Agregar Pago
          </Button>

          {paymentFields.map((field, index) => (
            <Grid container spacing={2} key={field.id} sx={{ marginTop: 2 }}>
              <Grid item xs={5}>
                <Autocomplete
                  options={paymentMethods || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  onChange={(_, value) => {
                    setValue(
                      `payments.${index}.paymentMethodId`,
                      value?.id || ""
                    );
                    clearErrors(`payments.${index}.paymentMethodId`);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Método de Pago"
                      error={!!errors.payments?.[index]?.paymentMethodId}
                      helperText={
                        errors.payments?.[index]?.paymentMethodId?.message
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  {...register(`payments.${index}.payedAmount`, {
                    required: "Payed amount is required",
                    valueAsNumber: true,
                  })}
                  label="Monto Pagado"
                  type="number"
                  fullWidth
                  error={!!errors.payments?.[index]?.payedAmount}
                  helperText={errors.payments?.[index]?.payedAmount?.message}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  select
                  label="Moneda"
                  {...register(`payments.${index}.currency`, {
                    required: "Moneda is required",
                  })}
                  fullWidth
                  error={!!errors.payments?.[index]?.currency}
                  helperText={errors.payments?.[index]?.currency?.message}
                >
                  <MenuItem value="BS">BS</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={1}>
                {paymentFields.length > 1 && (
                  <Button onClick={() => removePayment(index)} color="error">
                    Remover
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}

          <DialogActions>
            <Button onClick={onClose} color="info" disabled={isLoading}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? "Guardando..." : "Agregar Venta"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SaleForm;
