import api from "./authService";

export const fetchPaymentMethods = async () => {
  const response = await api.get("/payment-methods");
  return response.data;
};

export const createPaymentMethod = async (method) => {
  const response = await api.post("/payment-methods", method);
  return response.data;
};
