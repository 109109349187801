import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchProducts } from "../services/productService";
import { CircularProgress, Container, Typography, Button } from "@mui/material";
import ProductTable from "../components/ProductTable/ProductTable";
import ProductForm from "../components/ProductForm/ProductForm";
import { createProduct } from "../services/productService";

function Products() {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: products,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });

  const { mutate } = useMutation({
    mutationFn: createProduct,
    onSuccess: () => queryClient.invalidateQueries("products"),
  });

  const handleAddProduct = (product) => {
    mutate(product);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography>Error fetching productos</Typography>;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Productos
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2 }}
        onClick={() => setOpen(true)}
      >
        Agregar Producto
      </Button>
      <ProductTable products={products} />
      <ProductForm
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAddProduct}
      />
    </Container>
  );
}

export default Products;
