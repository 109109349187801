import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  IconButton,
  CssBaseline,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { logout } from "../../services/authService";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Logout } from "@mui/icons-material";

const drawerWidth = 240;

const DashboardLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ overflow: "auto" }}>
      <List>
        <ListItemButton component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton component={Link} to="/products">
          <ListItemText primary="Productos" />
        </ListItemButton>
        <ListItemButton component={Link} to="/sales">
          <ListItemText primary="Ventas" />
        </ListItemButton>
        <ListItemButton component={Link} to="/shipments">
          <ListItemText primary="Envíos" />
        </ListItemButton>
        <ListItemButton component={Link} to="/payment-methods">
          <ListItemText primary="Métodos de Pago" />
        </ListItemButton>
        <ListItemButton component={Link} to="/sale-channels">
          <ListItemText primary="Canales de Venta" />
        </ListItemButton>
        <ListItemButton onClick={logout}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* AppBar con botón de menú hamburguesa en mobile */}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div">
            Tecnozuve
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer permanente en pantallas grandes */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: isMobile ? "auto" : drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        {drawer}
      </Drawer>

      {/* Main Content Area */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {/* Espacio para la AppBar */}
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
