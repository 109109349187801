import api from "./authService";

export const fetchProducts = async () => {
  const response = await api.get("/products");
  return response.data;
};

export const createProduct = async (product) => {
  const response = await api.post("/products", product);
  return response.data;
};

export const updateProductStock = async ({ productId, stock }) => {
  const response = await api.patch(`/products/${productId}/stock`, { stock });
  return response.data;
};
