import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Sales from "./pages/Sales";
import Shipments from "./pages/Shipments";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import DashboardLayout from "./components/Layout/DashboardLayout";
import Products from "./pages/Products";
import PaymentMethods from "./pages/PaymentMethods";
import SaleChannels from "./pages/SaleChannels";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/sales" element={<Sales />} />
                  <Route path="/shipments" element={<Shipments />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/payment-methods" element={<PaymentMethods />} />
                  <Route path="/sale-channels" element={<SaleChannels />} />
                </Routes>
              </DashboardLayout>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
