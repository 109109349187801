import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateShipmentStatus } from "../../services/shipmentService";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ShipmentTable = ({ shipments }) => {
  const queryClient = useQueryClient();
  const [openRow, setOpenRow] = useState({});

  const { mutate: updateStatus } = useMutation({
    mutationFn: updateShipmentStatus,
    onSuccess: () => {
      queryClient.invalidateQueries("shipments");
    },
  });

  const handleStatusUpdate = (shipmentId) => {
    updateStatus({ shipmentId, status: "SHIPPED" });
  };

  const toggleRow = (id) => {
    setOpenRow((prevOpenRow) => ({
      ...prevOpenRow,
      [id]: !prevOpenRow[id],
    }));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Empresa de Envío</TableCell>
            <TableCell>Fecha de Orden</TableCell>
            <TableCell align="right">Envío ($)</TableCell>
            <TableCell align="right">Impuestos ($)</TableCell>
            <TableCell align="right">Total de unidades</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shipments.map((shipment) => (
            <React.Fragment key={shipment.id}>
              <TableRow>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => toggleRow(shipment.id)}
                  >
                    {openRow[shipment.id] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>{shipment.id}</TableCell>
                <TableCell>{shipment.shippingCompany}</TableCell>
                <TableCell>
                  {shipment.orderedDate ? new Date(shipment.orderedDate).toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }) : ""}
                </TableCell>
                <TableCell align="right">{shipment.shippingCost}</TableCell>
                <TableCell align="right">{shipment.taxCost}</TableCell>
                <TableCell align="right">{shipment.totalUnits}</TableCell>
                <TableCell align="right">
                  <Chip
                    label={shipment.status}
                    sx={(theme) => ({
                      backgroundColor:
                        shipment.status === "ORDERED"
                          ? theme.palette.warning.light
                          : theme.palette.success.main,
                      color: theme.palette.common.white,
                    })}
                  />
                </TableCell>
                <TableCell align="right">
                  {shipment.status === "ORDERED" && (
                    <IconButton
                      title="Update status to shipped"
                      color="primary"
                      onClick={() => handleStatusUpdate(shipment.id)}
                    >
                      <LocalShippingIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                >
                  <Collapse
                    in={openRow[shipment.id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box margin={2}>
                      <Typography variant="h6" gutterBottom component="div">
                        Productos
                      </Typography>
                      <Table size="small" aria-label="products">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre del Producto</TableCell>
                            <TableCell align="right">Unidades</TableCell>
                            <TableCell align="right">Costo Compra</TableCell>
                            <TableCell align="right">
                              Costo por Unidad
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {shipment.productShipments.map((productShipment) => (
                            <TableRow key={productShipment.id}>
                              <TableCell>
                                {productShipment.product.name}
                              </TableCell>
                              <TableCell align="right">
                                {productShipment.units}
                              </TableCell>
                              <TableCell align="right">
                                ${productShipment.purchaseCost}
                              </TableCell>
                              <TableCell align="right">
                                {productShipment.calculatedUnitCost}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShipmentTable;
