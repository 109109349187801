import api from "./authService";

export const fetchShipments = async () => {
  const response = await api.get("/shipments");
  return response.data;
};

export const createShipment = async (shipment) => {
  const response = await api.post("/shipments", shipment);
  return response.data;
};

export const updateShipmentStatus = async ({ shipmentId, status }) => {
  const response = await api.patch(`/shipments/${shipmentId}/status`, {
    status,
  });
  return response.data;
};
