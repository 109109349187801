import React from "react";
import { Container, Typography } from "@mui/material";
import SaleChannelTable from "../components/SaleChannelTable/SaleChannelTable";

function SaleChannels() {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Canales de Venta
      </Typography>
      <SaleChannelTable />
    </Container>
  );
}

export default SaleChannels;
