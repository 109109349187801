import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchPaymentMethods,
  createPaymentMethod,
} from "../../services/paymentMethodService";
import { useForm } from "react-hook-form";

const PaymentMethodTable = () => {
  const {
    data: paymentMethods,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["paymentMethods"],
    queryFn: fetchPaymentMethods,
  });

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: addPaymentMethod } = useMutation({
    mutationFn: createPaymentMethod,
    onSuccess: () => {
      queryClient.invalidateQueries("paymentMethods");
      setOpen(false);
      reset();
    },
  });

  const onSubmit = (data) => {
    addPaymentMethod(data);
  };

  if (isLoading) return <Typography>Loading payment methods...</Typography>;
  if (error) return <Typography>Error fetching payment methods.</Typography>;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        sx={{ marginBottom: 2 }}
      >
        Agregar Método de Pago
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentMethods.map((method) => (
              <TableRow key={method.id}>
                <TableCell>{method.id}</TableCell>
                <TableCell>{method.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={() => {reset(); setOpen(false)}}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Agregar Método de Pago</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Nombre del Método de Pago"
              {...register("name", { required: "Name is required" })}
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="info">
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Agregar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentMethodTable;
