import React, { useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProductStock } from "../../services/productService";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const ProductTable = ({ products }) => {
  const [editingProductId, setEditingProductId] = useState(null);
  const [newStock, setNewStock] = useState({});
  const queryClient = useQueryClient();
  const inputRef = useRef(null);

  const { mutate: updateStock } = useMutation({
    mutationFn: updateProductStock,
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      setEditingProductId(null);
    },
  });

  const handleStockChange = (event, productId) => {
    setNewStock({
      ...newStock,
      [productId]: event.target.value,
    });
  };

  const handleEditClick = (productId) => {
    setEditingProductId(productId);
    setNewStock({
      [productId]: products.find((p) => p.id === productId).stock,
    });
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleSaveClick = (productId) => {
    if (newStock[productId] !== undefined && newStock[productId] !== "") {
      updateStock({ productId, stock: Number(newStock[productId]) });
    } else {
      setNewStock({
        ...newStock,
        [productId]: products.find((p) => p.id === productId).stock,
      });
      setEditingProductId(null);
    }
  };

  const handleCancelClick = (productId) => {
    setNewStock({
      ...newStock,
      [productId]: products.find((p) => p.id === productId).stock,
    });
    setEditingProductId(null);
  };

  const handleKeyDown = (event, productId) => {
    if (event.key === "Enter") {
      handleSaveClick(productId);
    }
    if (event.key === "Escape") {
      handleCancelClick(productId);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre del Producto</TableCell>
            <TableCell align="right">Stock</TableCell>
            <TableCell align="right">Precio Sugerido ($)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell component="th" scope="row">
                {product.name}
              </TableCell>
              <TableCell align="right">
                {editingProductId === product.id ? (
                  <>
                    <TextField
                      value={newStock[product.id] ?? product.stock}
                      onChange={(event) => handleStockChange(event, product.id)}
                      onKeyDown={(event) => handleKeyDown(event, product.id)}
                      type="number"
                      size="small"
                      inputRef={inputRef}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              size="small"
                              onClick={() => handleSaveClick(product.id)}
                            >
                              <SaveIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => handleCancelClick(product.id)}
                            >
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                ) : (
                  <>
                    {product.stock}
                    <IconButton
                      color="primary"
                      onClick={() => handleEditClick(product.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
              <TableCell align="right">${product.suggestedSalePrice}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
