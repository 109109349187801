import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchShipments, createShipment } from "../services/shipmentService";
import { CircularProgress, Container, Typography, Button } from "@mui/material";
import ShipmentTable from "../components/ShipmentTable/ShipmentTable";
import ShipmentForm from "../components/ShipmentForm/ShipmentForm";

function Shipments() {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: shipments,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["shipments"],
    queryFn: fetchShipments,
  });

  const { mutate } = useMutation({
    mutationFn: createShipment,
    onSuccess: () => queryClient.invalidateQueries("shipments"),
  });

  const handleAddShipment = (shipment) => {
    mutate(shipment);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography>Error fetching shipments</Typography>;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Envíos
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2 }}
        onClick={() => setOpen(true)}
      >
        Agregar Envío
      </Button>
      <ShipmentTable shipments={shipments} />
      <ShipmentForm
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAddShipment}
      />
    </Container>
  );
}

export default Shipments;
