import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchSales } from "../../services/saleService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const SaleTable = () => {
  const {
    data: sales,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["sales"],
    queryFn: fetchSales,
  });

  const [openRow, setOpenRow] = useState({});

  const toggleRow = (id) => {
    setOpenRow((prevOpenRow) => ({
      ...prevOpenRow,
      [id]: !prevOpenRow[id],
    }));
  };

  if (isLoading) return <Typography>Loading sales...</Typography>;
  if (error) return <Typography>Error fetching sales.</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Nombre del Comprador</TableCell>
            <TableCell>Pagado BS</TableCell>
            <TableCell>Pagado USD</TableCell>
            <TableCell>Total Sugerido ($)</TableCell>
            <TableCell>Total Delivery ($)</TableCell>
            <TableCell>Conversión</TableCell>
            <TableCell>Canal de Venta</TableCell>
            <TableCell>Productos</TableCell>
            <TableCell align="right">Fecha de Venta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.map((sale) => (
            <React.Fragment key={sale.id}>
              <TableRow>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => toggleRow(sale.id)}
                  >
                    {openRow[sale.id] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>{sale.id}</TableCell>
                <TableCell>{sale.buyerName}</TableCell>
                <TableCell>{sale.totalPayedBS} BS</TableCell>
                <TableCell>${sale.totalPayedUSD}</TableCell>
                <TableCell>${sale.totalSuggestedPrice}</TableCell>
                <TableCell>${sale.deliveryCost}</TableCell>
                <TableCell>{sale.conversionRate}</TableCell>
                <TableCell>{sale.saleChannel.name}</TableCell>
                <TableCell>
                  {sale.saleProducts.map((product, index) => (
                    <Typography key={index + product.id} variant="body2" sx={{marginBottom: 1}}>
                      {product.product.name}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell align="right">
                  {dayjs.utc(sale.saleDate).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f9f9f9" }}
                  colSpan={12}
                >
                  <Collapse in={openRow[sale.id]} timeout="auto" unmountOnExit>
                    <Box margin={2}>
                      <Typography variant="h6" gutterBottom component="div">
                        Productos Vendidos
                      </Typography>
                      <Table size="small" aria-label="sale products">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Producto</TableCell>
                            <TableCell align="right">Cantidad</TableCell>
                            <TableCell align="right">
                              Precio de Venta Unitario ($)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sale.saleProducts.map((product) => (
                            <TableRow key={product.id}>
                              <TableCell>{product.product.id}</TableCell>
                              <TableCell>{product.product.name}</TableCell>
                              <TableCell align="right">
                                {product.quantity}
                              </TableCell>
                              <TableCell align="right">
                                {product.unitPrice}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{ mt: 2 }}
                      >
                        Pagos en la venta
                      </Typography>
                      <Table size="small" aria-label="sale payments">
                        <TableHead>
                          <TableRow>
                            <TableCell>Método de Pago</TableCell>
                            <TableCell align="right">Monto</TableCell>
                            <TableCell align="right">Moneda</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sale.salePayments.map((payment) => (
                            <TableRow key={payment.id}>
                              <TableCell>
                                {payment.paymentMethod.name}
                              </TableCell>
                              <TableCell align="right">
                                {payment.payedAmount}
                              </TableCell>
                              <TableCell align="right">
                                {payment.currency}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SaleTable;
