import React, { useState } from "react";
import { Container, Typography, Button } from "@mui/material";
import SaleTable from "../components/SaleTable/SaleTable";
import SaleForm from "../components/SaleForm/SaleForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSale } from "../services/saleService";

function Sales() {
  const [open, setOpen] = useState(false);
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();

  const { mutate: addSale, isLoading } = useMutation({
    mutationFn: createSale,
    onSuccess: () => {
      queryClient.invalidateQueries("sales");
      setOpen(false);
      setFormError(null);
    },
    onError: (error) => {
      setFormError("Error al crear la venta. Intenta de nuevo.");
    },
  });

  const handleAddSale = (data, resetForm) => {
    addSale(data, {
      onSuccess: () => {
        resetForm();
      },
    });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Ventas
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2 }}
        onClick={() => {
          setFormError(null);
          setOpen(true);
        }}
      >
        Agregar Venta
      </Button>
      <SaleTable />
      <SaleForm
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAddSale}
        errorMessage={formError}
        isLoading={isLoading}
      />
    </Container>
  );
}

export default Sales;
